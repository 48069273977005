import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDUA27hS9iAfgGkNVzmJq4X64be9zIJZFc",
    authDomain: "solholtdk-939bc.firebaseapp.com",
    projectId: "solholtdk-939bc",
    storageBucket: "solholtdk-939bc.appspot.com",
    messagingSenderId: "855302309632",
    appId: "1:855302309632:web:dd2099c29fa59a1df195e5"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);


