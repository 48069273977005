
import { useEffect, useState } from 'react';
import { dbGetAlbums, dbGetCategories } from '../models/photosModel';
import { useSiteContext } from '../utils/siteContext'

export default function Page() {
  const { albums, setAlbums, albumCategories, setAlbumCategories, login, authenticated } = useSiteContext();
  const [selectedAlbum, setSelectedAlbum] = useState([]);

  const selectCategory = async (category) => {
    //const headers = ["", "", "Familie", "Sightseeing"];
    if (category === "Årbog") {
      if (!albums.yearBooks.length) {
        const dbResult = await dbGetAlbums(category);
        if (dbResult !== undefined) {
          setAlbums({ ...albums, yearBooks: dbResult });
          setSelectedAlbum(dbResult);
        }
      }
      else {
        setSelectedAlbum(albums.yearBooks);
        console.log('category from cache', category)
      }
    }
    else if (category === "Solholt") {
      if (!albums.solholt.length) {
        const dbResult = await dbGetAlbums(category);
        if (dbResult !== undefined) {
          setAlbums({ ...albums, solholt: dbResult });
          setSelectedAlbum(dbResult);
        }
      }
      else {
        setSelectedAlbum(albums.solholt);
        console.log('category from cache', category)
      }
    }
    else if (category === "Familie") {
      if (!albums.family.length) {
        const dbResult = await dbGetAlbums(category);
        if (dbResult !== undefined) {
          setAlbums({ ...albums, family: dbResult });
          setSelectedAlbum(dbResult);
        }
      }
      else {
        setSelectedAlbum(albums.family);
        console.log('category from cache', category)
      }
    }
    else if (category === "Sightseeing") {
      if (!albums.sightseeing.length) {
        const dbResult = await dbGetAlbums(category);
        if (dbResult !== undefined) {
          setAlbums({ ...albums, sightseeing: dbResult });
          setSelectedAlbum(dbResult);
        }
      }
      else {
        setSelectedAlbum(albums.sightseeing);
        console.log('category from cache', category)
      }
    }
  }

  useEffect(() => {
    document.title = 'Solholt - Fotos';
  }, []);

  useEffect(() => {
    const getCategories = async () => {
      if (!albumCategories.length) {
        const dbResult = await dbGetCategories();
        if (dbResult !== undefined) {
          console.log('get categories')
          setAlbumCategories(dbResult);
        }
      }
    }
    getCategories();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className='center'>
        {
          selectedAlbum.length === 0 &&
          albumCategories.map((entry, index) => {
            return (
              <div key={index} className='photoCategory'>
                <button className='photoCategoryLink' onClick={e => selectCategory(entry.category)}>{entry.category}</button>
                <div className='img-shadow'>
                  <img onClick={e => selectCategory(entry.category)} alt={entry.title} src={entry.thumbnail} />
                </div>
              </div>
            )
          })
        }
        {
          selectedAlbum.length !== 0 &&
          (
            <>
              <div>
                <button className='photoCategoryLink' onClick={e => setSelectedAlbum([])}>Tilbage til kategorier</button>
                <br /><br />
              </div>
              {
                selectedAlbum.map((entry, index) => {
                  return (
                    <div key={index} className='photoCategory'>
                      <div className='photoCategoryLink'>{entry.title.replace(entry.category, '')}</div>
                      <div className='img-shadow'>
                        <a href={entry.link} target="_blank" rel="noreferrer">
                          <img alt={entry.title} src={entry.thumbnail} />
                        </a>
                      </div>
                    </div>
                  )
                })
              }
            </>
          )
        }
      </div>
      <div className='center'>
        {
          !authenticated && (
            <button className='photoCategoryLink' onClick={login}>Setup</button>
          )
        }
      </div>
    </>
  );
}

