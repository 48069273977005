import React, { useEffect } from 'react';
import ImageAllan from '../assets/aml3.jpg';
import { Container, Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

export default function PageAllan() {
  useEffect(() => {
    document.title = 'Solholt - Allan';
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <h1>CV</h1>
        </Col>
      </Row>
      <Row>
        <Col xs={9}>
          <ul>
            <li>Allan Molsen Larsen</li>
            <li>Født 1973 i Frederikshavn</li>
            <li>Bydreng ved Electra i Frederikshavn, 1989-1992</li>
            <li>HTX, el-teknisk linie ved Frederikshavn tekniske Skole, 1993</li>
            <li>Serviceassistent ved Shell Service (Skalborg), 1994-1996</li>
            <li>Ansvarlig for computerafdelingen ved Bilka Skalborg 1996-1997</li>
            <li>Rygsæksrejsende i Australien og New Zealand maj-november 1997</li>
            <li>Ansat ved Martin Manufactoring i Frederikshavn december-januar 1997-1998</li>
            <li>1. mand ved Data Discount Aalborg 1998-2000</li>
            <li>Datamatiker ved NOEA, Nordjyllands Erhvervsakademi, Aalborg 2002</li>
            <li>Fritidsjob som klatreinstruktør ved Abekatten 2000-2002</li>
            <li>Kasserer i Nordjydsk Klatreklub i 2000 samt 2002-2003</li>
            <li>Systemudvikler ved Dafolo A/S 2002-2004</li>
            <li>Systemudvikler ved Dafolo Marketing A/S 2004 - 2007</li>
            <li>Systemudvikler ved Nordjysk Elhandel A/S 2007 - 2017</li>
            <li>Selvstændig 2018 - 2020</li>
            <li>Seniorudvikler ved Zentura A/S 2020 - </li>
          </ul>
        </Col>
        <Col>
          <img src={ImageAllan} width="147" height="143" alt="Allan Molsen Larsen" />
        </Col>
      </Row>
      <Row>
        <Col>
          <h1>Fritids projekter</h1>
          <ul>
            <li>
              <NavLink to="/brygning">Brygning</NavLink>, sider om vores brygning
            </li>
            <li>
              <NavLink to="wood">Trædrejning</NavLink>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

