import { createContext, useContext, useState } from "react";
import { GoogleAuthProvider, getAuth, signInWithPopup, signOut, setPersistence, browserLocalPersistence, onAuthStateChanged } from "firebase/auth";
import { app } from './firebase';

const auth = getAuth(app);
const googleAuthProvider = new GoogleAuthProvider();
const AuthContext = createContext();

export const useSiteContext = () => useContext(AuthContext);
export const SiteContextProvider = ({ children }) => {
    const [authenticated, setAuthenticated] = useState(false);
    const [user, setUser] = useState({});
    const [albums, setAlbums] = useState({
        yearBooks: [],
        solholt: [],
        family: [],
        sightseeing: []
    });
    const [albumCategories, setAlbumCategories] = useState([]);

    onAuthStateChanged(auth, (user) => {
        if (user) {
            setAuthenticated(true);
            setUser(user.displayName);
        } else {
            console.log("No user");
        }
    });

    async function login(callback) {
        try {
            await setPersistence(auth, browserLocalPersistence);

            const result = await signInWithPopup(auth, googleAuthProvider);
            if (result.user) {
                setAuthenticated(true);
                setUser(result.user.displayName);
                console.log(result.user.displayName);
                if (callback)   // If a callback is provided, call it
                    callback();
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const logout = () => {
        try {
            signOut(auth).then(() => {
                setAuthenticated(false);
                setUser({});
            });
        }
        catch {
        }
    }

    return <AuthContext.Provider value={{
        authenticated, user, login, logout, albums, setAlbums,
        albumCategories, setAlbumCategories
    }}>
        {children}
    </AuthContext.Provider>
}
