import { getDocs, setDoc, doc, collection, query, where, orderBy, onSnapshot } from "firebase/firestore";
import { db } from '../utils/firebase';
import moment from 'moment';
import 'moment/locale/da';
moment().locale('da');

const logCollectionName = "shopping";

class ShoppingItem {
    constructor(item) {
        if (item !== undefined) {
            this.id = Number(item.id);
            this.active = item.active;
            this.buy = item.buy;
            this.buyCounter = item.buyCounter;
            this.count = item.count;
            this.freezer = item.freezer;
            this.canOutdate = item.canOutdate;
            this.title = item.title;
            this.category = item.category ? item.category : 'Andet';
            this.categorySortOrder = item.category && item.category !== 'Andet' ? item.category : 'Å';
            this.priority = item.priority ? item.priority : false;
            this.updateTs = item.updateTs;

            if (isNaN(this.id)) {
                console.log('ShoppingItem: id is not a number', item);
            }
        }
    }
}

// const dbGetShoppingList = (callback) => {
//     try {


//         // const result = await getDocs(_query);
//         // let list = [];
//         // if (result !== undefined) {
//         //     if (result.docs.length > 0) {
//         //         result.docs.forEach((doc) => {
//         //             list.push(new ShoppingItem({ id: doc.id, ...doc.data() }));
//         //         });
//         //     }
//         // }
//         // console.log('Loading all items', list.length);
//         //return list;
//     }
//     catch (error) {
//         console.log(error);
//         return undefined;
//     }
// }

const dbGetPartialShoppingList = async (afterDate) => {
    try {
        const collectionRef = collection(db, logCollectionName);
        const _query = query(collectionRef,
            where("active", "==", true),
            where("updateTs", ">", afterDate),
            orderBy("updateTs", "desc"));

        const result = await getDocs(_query);
        let list = [];
        if (result !== undefined) {
            if (result.docs.length > 0) {
                result.docs.forEach((doc) => {
                    list.push(new ShoppingItem({ id: doc.id, ...doc.data() }));
                });
            }
        }
        console.log('Loading new items', list.length);
        return list;
    }
    catch (error) {
        console.log(error);
        return [];
    }
}

export const refreshPartialShoppingItem = async (list, afterDate, startUpdating, stopUpdating) => {

    startUpdating();
    let newList = await dbGetPartialShoppingList(afterDate);
    stopUpdating();

    let newestUpdate = moment(newList[0].updateTs.toDate()).toDate();

    if (newList.length > 0) {
        newList.forEach((item) => {
            let index = list.findIndex((i) => i.id === item.id);
            if (index > -1) {
                let hasDeletedSet = list[index].delete;
                list[index] = item;
                list[index].delete = hasDeletedSet;
            }
            else {
                list.push(item);
            }
        });
    }

    return { newestUpdate, ...reorderList(list) };
}

export const dbInitListener = (updateCallback, spinnerCallback) => {
    try {
        spinnerCallback(true);
        const collectionRef = collection(db, logCollectionName);
        const _query = query(collectionRef,
            where("active", "==", true),
            orderBy("updateTs", "desc"));

        return onSnapshot(_query, (querySnapshot) => {
            let list = [];
            querySnapshot.forEach((doc) => {
                list.push(new ShoppingItem({ id: doc.id, ...doc.data() }));
            });

            let newestUpdate = moment(list[0].updateTs.toDate()).toDate();
            updateCallback({ newestUpdate, ...reorderList(list) });
            spinnerCallback(false);            
        });
    }
    catch (error) {
        console.log(error);
        return undefined;
    }
}

export const cloneItem = (item) => {
    return new ShoppingItem({
        id: item.id,
        title: item.title,
        active: item.active,
        freezer: item.freezer,
        buy: item.buy,
        buyCounter: item.buyCounter,
        count: item.count,
        canOutdate: item.canOutdate,
        category: item.category,
        priority: item.priority,
        delete: item.delete
    });
}

export const reorderList = (list) => {
    //console.log(list)
    //remove categories
    list = list.filter((item) => item.type !== 'category');

    //order by buyCounter
    list.sort((a, b) => b.buyCounter - a.buyCounter);

    for (let i = 0; i < list.length; i++) {
        if (i < 22) {
            list[i].priority = true;
        }
        else
            list[i].priority = false;
    }

    const collator = new Intl.Collator('da');

    list.sort((a, b) => collator.compare(a.title, b.title));
    list.sort((a, b) => collator.compare(a.categorySortOrder, b.categorySortOrder));

    let newList = [];
    let categories = [];
    let tmpCategory = { category: '' };
    for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (item.category !== tmpCategory.category) {
            tmpCategory = {
                category: item.category,
                id: 'category' + item.id,
                type: 'category'
            };
            categories.push(tmpCategory.category);
            newList.push(tmpCategory);
        }
        newList.push(item);
    }
    return { list: newList, categories };
}

export const dbUpdate = async (item, list = undefined) => {
    try {
        if (!item.id && list) {
            let maxId = 0;
            list.forEach((i) => {
                if (!isNaN(i.id) && Number(i.id) > maxId) maxId = Number(i.id);
            });
            item.id = maxId + 1;
        }

        if (item.id) {
            await setDoc(doc(db, logCollectionName, item.id + ''),
                {
                    active: true,
                    buy: item.buy ? item.buy : false,
                    buyCounter: item.buyCounter ? item.buyCounter : 0,
                    category: item.category ? item.category : 'Andet',
                    count: item.count ? item.count : 0,
                    freezer: item.freezer ? item.freezer : false,
                    canOutdate: item.canOutdate ? item.canOutdate : false,
                    title: item.title,
                    updateTs: new Date()
                });
        }
        console.log('dbUpdate', item)
        return true;
    }
    catch (error) {
        console.log(error, item);
        return false;
    }
}

// const dbCreate = async (id, item) => {
//     try {
//         await setDoc(doc(db, logCollectionName, id), item);
//         return true;
//     }
//     catch (error) {
//         console.log(error, id, item);
//         return false;
//     }
// }
