import { Row, Col, Button } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useSiteContext } from '../utils/siteContext';
import { dbInitListener, reorderList, cloneItem, dbUpdate } from '../models/shoppingModel';

export default function Page() {
  const { login, authenticated } = useSiteContext();
  const [shoppingItems, setShoppingItems] = useState([]);
  const [categories, setCategories] = useState([]);
  const [basketItems, setBasketItems] = useState([]);
  const [freezerItems, setFreezerItems] = useState([]);
  const [view, setView] = useState('basket');
  const [edit, setEdit] = useState(undefined);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [showZeroFreezer, setShowZeroFreezer] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [lastUpdated, setLastUpdated] = useState(new Date('2023-03-30'));

  const spinnerCallback = (u) => {
    setUpdating(u);
  }

  const updateCallback = (data) => {
    console.log('updateMe1');
    setLastUpdated(new Date());

    console.log('update basket ', basketItems);
    // for (let i = 0; i < basketItems.length; i++) {
    //   if (basketItems[i].delete === true) {

    //     let item = data.list.filter(li => li.id === basketItems[i].id);
    //     if (item.length > 0)
    //       item[0].delete = true;
    //   }
    // }
    console.log('updateMe2a');

    setShoppingItems(data.list);
    setCategories(data.categories);
    setupBasketItems(data.list);
    setupFreezerItems(data.list);
  }

  // User has switched back to the tab
  const onFocus = async () => {
    console.log("Tab is in focus")
    // console.log(new Date())
    // console.log(lastUpdated);
    //console.log(Math.abs((new Date()) - lastUpdated) / (1000 * 60));
    // const data = await refreshPartialShoppingItem(shoppingItems, lastUpdated, startUpdating, stopUpdating);
    // setShoppingItems(data.list);
    // setCategories(data.categories);
    // setupBasketItems(data.list);
    // setupFreezerItems(data.list);
    // console.log('refreshed newest update: ' + data.newestUpdate);
    // setLastUpdated(data.newestUpdate);
  };

  //focus event
  useEffect(() => {
    window.addEventListener("focus", onFocus);
    // Calls onFocus when the window first loads
    onFocus();
    // Specify how to clean up after this effect:
    return () => {
      window.removeEventListener("focus", onFocus);
    };

    // eslint-disable-next-line
  }, []);

  const initDataListener = () => {
    const unsubscribe = dbInitListener(updateCallback, spinnerCallback);

    return () => {
      unsubscribe();
      console.log('unsubscribe dbInitListener')
    };
  }

  useEffect(() => {
    //document.title = 'Solholt indkøb';
    console.log('page useEffect, get shopping data')

    initDataListener();

    // eslint-disable-next-line
  }, []);

  const setupBasketItems = (data) => {
    let basketItemData = data.filter(item => item.buy);
    basketItemData.sort((a, b) => { return a.category > b.category ? 1 : -1 });
    basketItemData.sort((a, b) => { return a.title > b.title ? 1 : -1 });
    setBasketItems(basketItemData);
  }
  const setupFreezerItems = (data) => {
    let freezerItemData = data.filter(item => item.freezer);
    const collator = new Intl.Collator('da');
    freezerItemData.sort((a, b) => collator.compare(a.title, b.title));

    setFreezerItems(freezerItemData);
  }
  const toggleDeletedBasketItems = (item) => {
    item.delete = !item.delete;
    setBasketItems([...basketItems]);
  }

  const toggleBuyBasketItems = (item) => {
    item.buy = !item.buy;
    dbUpdate(item);
    console.log('changing item buy to: ' + item.buy + ' for item: ' + item.title);
    //setupBasketItems(shoppingItems);
  }

  const toggleCategoryItems = (category) => {
    let newCategory = category;
    if (category === selectedCategory)
      newCategory = 'none';

    console.log('selected category: ' + newCategory);
    setSelectedCategory(newCategory);
  }

  const deleteBasketItems = () => {
    let newBasketItems = basketItems.filter(bi => !bi.delete);
    for (let i = 0; i < basketItems.length; i++) {
      if (basketItems[i].delete) {
        basketItems[i].delete = false;
        basketItems[i].buy = false;
        basketItems[i].buyCounter++;
        dbUpdate(basketItems[i]);
      }
    }
    setBasketItems(newBasketItems);
  }

  const saveBasketItem = () => {
    for (let i = 0; i < shoppingItems.length; i++) {
      if (shoppingItems[i].id === edit.id) {
        shoppingItems[i] = cloneItem(edit);
        break;
      }
    }
    console.log('saveBasketItem: ', edit)
    dbUpdate(edit, shoppingItems);
    setShoppingItems(reorderList(shoppingItems).list);
    setEdit(undefined);
  }

  return (
    <>
      {
        authenticated && (
          <>
            <div className="center navbar navbar-expand-lg navbar-light">
              <div className="navLeftRightIcons alignLeft">
                {
                  view === 'basket' && (
                    <i className="icons" onClick={() => { deleteBasketItems() }}>&#xe800;</i>
                  )
                }
              </div>
              <div className="navIcons">
                <i className={"icons" + (view === 'basket' ? ' iconSelected' : '')} onClick={() => { setView('basket') }}>&#xe801;</i>
                <i className={"icons" + (view === 'freezer' ? ' iconSelected' : '')} onClick={() => { setView('freezer') }}>&#xe802;</i>
                <i className={"icons" + (view === 'favourites' ? ' iconSelected' : '')} onClick={() => { setView('favourites') }}>&#xe809;</i>
              </div>
              <div className="navLeftRightIcons alignRight">
                {
                  (view === 'freezer' || view === 'basket' || view === 'favourites') && (
                    <>
                      <i className="icons newItem" onClick={() => { setView('allItems'); setEdit({id: '', title: '', newItem:true}) }}>&#xe807;</i>
                      <i className={"icons animate-spin" + (updating === true ? '' : ' hide')}>&#xe834;</i>
                      <i className="icons" onClick={() => { setView('allItems') }}>&#xe803;</i>
                    </>
                  )
                }
              </div>
            </div >
            {
              view === 'basket' && (
                <ul className="list-group">
                  {
                    basketItems.map((item, index) => (
                      <li key={item.id} onClick={() => { toggleDeletedBasketItems(item) }} className={"list-group-item d-flex justify-content-between align-items-center" + (item.delete ? ' strikeOut' : '')}>
                        {item.title}
                        {/* <span className="badge bg-primary rounded-pill">-</span> */}
                      </li>
                    ))
                  }
                </ul>
              )
            }
            {
              view === 'favourites' && (
                <ul className="list-group">
                  {
                    shoppingItems.map((item, index) => {
                      if (item.priority) {
                        return (
                          <li key={item.id}
                            className={"list-group-item d-flex justify-content-between align-items-center edit"
                              + (item.delete ? ' strikeOut' : '')
                              + (item.buy ? ' basketItem' : '')
                            }
                            onClick={() => {
                              if (item.type !== 'category') {
                                toggleBuyBasketItems(item)
                              }
                              else {
                                toggleCategoryItems(item.category)
                              }
                            }}
                          >
                            {item.title}
                          </li>
                        )
                      }
                      else return null
                    }
                    )
                  }
                </ul>
              )
            }
            {
              view === 'allItems' && (
                <>
                  {
                    !edit && (
                      <ul className="list-group">
                        {
                          shoppingItems.map((item, index) => (
                            <li key={item.id}
                              className={"list-group-item d-flex justify-content-between align-items-center edit"
                                + (item.delete ? ' strikeOut' : '')
                                + (item.buy ? ' basketItem' : '')
                                + (item.type === 'category' ? ' category' :
                                  (item.category === selectedCategory ? ' show' : ' hide'))
                              }>
                              <div className='flexfill' onClick={() => {
                                if (item.type !== 'category') {
                                  toggleBuyBasketItems(item)
                                }
                                else {
                                  toggleCategoryItems(item.category)
                                }
                              }}>
                                {item.type !== 'category' ? item.title : item.category}
                              </div>
                              {
                                item.type !== 'category' && (
                                  <i className="icons" onClick={() => { setEdit(item) }}>&#xe807;</i>
                                )
                              }
                            </li>
                          ))
                        }
                      </ul>
                    )
                  }
                  {
                    edit && (
                      <li className="list-group-item edit">
                        {
                          edit.newItem && (
                            <div className="form-group">
                              <label htmlFor="id" className="form-label mt-4">Ny vare</label>
                              
                            </div>
                          )
                        }
                        <div className="form-group">
                          <label htmlFor="title" className="form-label mt-4">Navn</label>
                          <input type="text" className="form-control" id="title" placeholder="Navn"
                            value={edit.title} onChange={(e) => { edit.title = e.target.value; setEdit({ ...edit }) }} />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleSelect1" className="form-label mt-4">Kategori</label>
                          <select className="form-select" id="exampleSelect1" value={edit.category}
                            onChange={(e) => { edit.category = e.target.value; setEdit({ ...edit }) }}>
                            {
                              categories.map((category, index) => (
                                <option id={category} key={index}>{category}</option>
                              ))
                            }
                          </select>
                        </div>
                        <div className="form-group">
                          <input type="checkbox" className="form-check-input" id="freezer"
                            checked={edit.freezer} onChange={(e) => { edit.freezer = e.target.checked; setEdit({ ...edit }) }} />
                          <label htmlFor="freezer" className="form-label mt-4">Fryser</label>
                        </div>
                        {
                          edit.freezer && (
                            <>
                              <div className="form-group">
                                <input type="checkbox" className="form-check-input" id="canOutdate"
                                  checked={edit.canOutdate} onChange={(e) => { edit.canOutdate = e.target.checked; setEdit({ ...edit }) }} />
                                <label htmlFor="canOutdate" className="form-label mt-4">Kan udløbe</label>
                              </div>
                            </>
                          )
                        }
                        <div className="form-group center">
                          <button type="button" className='btn btn-warning' size="sm" onClick={() => { setEdit(undefined) }}>Fortryd</button>
                          <button type="button" className='btn btn-success' size="sm" onClick={() => { saveBasketItem() }}>Gem</button>
                        </div>
                      </li>
                    )
                  }
                </>
              )
            }
            {
              view === 'freezer' &&
              (
                <>
                  <div className="form-check shoppingFirstItem">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked1" defaultChecked={showZeroFreezer} onClick={() => { setShowZeroFreezer(!showZeroFreezer) }} />
                    <label className="form-check-label" htmlFor="flexCheckChecked1">
                      Vis fødevarer med 0 antal
                    </label>
                  </div>
                  <ul className="list-group">
                    {
                      freezerItems.map((item, index) => {
                        if ((showZeroFreezer && item.count === 0) || item.count > 0) {
                          return (
                            <li key={item.id} onClick={() => { toggleDeletedBasketItems(item) }} className={"list-group-item d-flex justify-content-between align-items-center" + (item.delete ? ' strikeOut' : '')}>
                              {item.title}
                              {
                                item.count > 0 && (
                                  <span className="badge bg-primary rounded-pill">{item.count}</span>
                                )
                              }
                            </li>
                          )
                        }
                        else
                          return null;
                      })
                    }
                  </ul>
                </>
              )
            }
          </>
        )
      }
      {
        !authenticated && (
          <Row>
            <Col className='center' >
              <Button variant="primary" onClick={() => login(initDataListener)}>Login</Button>
            </Col>
          </Row>
        )
      }
    </>
  );
}

