import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './Components/Router';
import './assets/bootstrap.min.css';
import './assets/style.css';
import './assets/fontello.ttf';
import { SiteContextProvider } from './utils/siteContext';

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
    <SiteContextProvider>
      <Router />
    </SiteContextProvider>
  // </React.StrictMode>
);