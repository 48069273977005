import React, { useEffect } from 'react';
import ImageChristian from '../assets/ncl2.jpg';
import ImageUnderviser from '../assets/underviser.jpg';
import { Container, Row, Col } from 'react-bootstrap';

export default function PageChristian() {
  useEffect(() => {
    document.title = 'Solholt - Christian';
  }, []);

  return (
    <Container>
      <Row>
        <Col>
          <h1>CV</h1>
        </Col>
      </Row>

      <Row>
        <Col xs={9}>
          <ul>
            <li>Niels Christian Larsen</li>

            <li>
              Født 8. februar 1972 i Århus. <a href="http://slaegt.solholt.dk/person.jsp" rel="noreferrer" target="_blank"><span>Slægtsdatabase</span></a>
            </li>

            <li>
              Matematisk-fysisk student fra <a href="http://www.hj-gym.dk/" rel="noreferrer" target="_blank"><span>Hjørring Gymnasium</span></a> 1990
            </li>

            <li>
              Civilingeniør med speciale i datateknik (dataingeniør) fra <a href="http://ekstern.aau.dk/" rel="noreferrer" target="_blank">
                Aalborg Universitet</a> 1996.
            </li>

            <li>
              Særlig interesse for objektorienterede og dynamiske
              programmeringssprog og -værktøjer, herunder udvikling af
              grafværktøjet <a href="http://www.cs.auc.dk/~normark/ginger.html" rel="noreferrer" target="_blank">Ginger</a>
            </li>

            <li>
              Ansat som underviser på Datamatikeruddannelsen ved Ikast Handelsskole 1996-2001
            </li>

            <li>
              Gennemført Handelsskolernes Pædagogikum foråret 1999 ved DEL, Århus
            </li>

            <li>
              Koordinator for Datamatikeruddannelsen ved Ikast Handelsskole skoleåret 2000/2001
            </li>

            <li>
              Ansat som underviser i Kursusafdelingen ved <a href="http://www.fhavnhs.dk/" rel="noreferrer" target="_blank">
                Frederikshavn Handelsskole </a> 2001-2004
            </li>

            <li>
              Ansat som software engineer ved <a href="http://www.conscius.com/" rel="noreferrer" target="_blank">conscius</a> a/s
              marts-juli 2004
            </li>

            <li>
              Ansat som underviser i matematik på HHX ved <a href="http://www.fhavnhs.dk/" rel="noreferrer" target="_blank">
                Frederikshavn Handelsskole
              </a> fra august 2004
            </li>
          </ul>
        </Col>
        <Col>
          <a href="http://slaegt.solholt.dk/image.jsp?file=id5.jpg" rel="noreferrer">
            <img src={ImageChristian} width="147" alt="Christian" />
          </a>
        </Col>
      </Row>

      <Row>
        <Col>
          <img src={ImageUnderviser} width="358" height="475" alt="Underviser" />
          ...Hvem ved hvor man ender?
        </Col>
        <Col>
          Min file extension:<br />
          <a href="http://www.bbspot.com/News/2004/10/extension_quiz.php">
            <img src="http://www.bbspot.com/Images/News_Features/2004/10/file_extensions/inf.jpg" width="300" height="90" alt="You are .inf You are informative. When you are gone you make life very difficult for others." border="0" /><br />
            Which File Extension are You?
          </a>
        </Col>
      </Row>
    </Container>
  );
}

