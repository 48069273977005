import { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import Navbar from './Navbar';
import { Outlet, useLocation } from 'react-router-dom';

function Page() {
  const location = useLocation();

  useEffect(() => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = location.pathname !== "/shopping" ? "favicon.ico" : "shopping_icon.png";
    document.getElementsByTagName("head")[0].appendChild(link);
  }, [location]);

  return (
    <>
      <Navbar />
      <div className="content">
        <Container fluid id="outerContainer" className={location.pathname !== "/shopping" ? '' : 'shopping'}>
          <div className="justify-content-md-center">
            <Outlet />
          </div>
        </Container>
        {
          location.pathname !== "/shopping" && (
            <div id="bottom">
              Niels Christian Larsen og Allan Molsen Larsen
            </div>
          )
        }
      </div>
    </>
  );
}

export default Page;
