import React, { useEffect } from 'react';

export default function Page404() {
  useEffect(() => {
    document.title = 'Solholt - not found';
  }, []);

  return (
    <div className='col center'>
          <h1>Siden blev ikke fundet</h1>
          <br/>
          <br/>
          <br/>
          <br/>
          <br/>
    </div>
  );
}

