import { query, getDocs, collection, where, orderBy } from "firebase/firestore";
import { db } from '../utils/firebase';
import moment from 'moment';
import 'moment/locale/da';
moment().locale('da');

const logCollectionName = "photos";

export class AlbumEntry {
    constructor(item) {
        if (item !== undefined) {
            this.title = item.title;
            this.thumbnail = item.thumbnail;
            this.link = item.link;
            this.category = item.category;
        }
    }
}

export const dbGetCategories = async () => {
    try {
        const collectionRef = collection(db, logCollectionName);
        let albums = [];

        const queryAlbums = query(
            collectionRef,
            where("active", "==", true),
            where("newest", "==", true),
            orderBy("updatedTs", "desc"));

        const result = await getDocs(queryAlbums);
        if (result !== undefined) {
            if (result.docs.length > 0) {
                result.docs.forEach((doc) => {
                    albums.push(new AlbumEntry(doc.data()));
                });
            }
        }

        return albums;

    }
    catch (error) {
        console.log("db error get categories", error);
        return undefined;
    }
}

export const dbGetAlbums = async (category) => {
    try {
        console.log('dbGetAlbums')
        const collectionRef = collection(db, logCollectionName);
        let albums = [];

        const queryAlbums = query(
            collectionRef,
            where("active", "==", true),
            where("category", "==", category),
            orderBy("updatedTs", "desc"));

        const result = await getDocs(queryAlbums);
        if (result !== undefined) {
            if (result.docs.length > 0) {
                result.docs.forEach((doc) => {
                    albums.push(new AlbumEntry(doc.data()));
                });
            }
        }

        return albums;

    }
    catch (error) {
        console.log(error);
        return [];
    }
}

// export const dbUpdateData = async () => {

//     const headers = ["Årbog", "Solholt", "Familie", "Sightseeing"];

//     for (let i = 0; i < albumsData.length; i++) {
//         const item = albumsData[i];
//         const newItem = {}
//         newItem.id = item.Id;
//         newItem.title = item.Title;
//         newItem.thumbnail = item.Thumbnail;
//         newItem.link = item.Link;
//         newItem.updatedTs = new Date(item.UpdatedTs);
//         newItem.active = item.Active == "1";
//         newItem.newest = false;
//         //const result = await dbCreate(id, title, thumbnail, link, updatedTs, active);
//         let found = false;

//         for (let i = 0; i < headers.length; i++) {
//             const header = headers[i];
//             if (newItem.title.indexOf(header) === 0) {
//                 found = true;
//                 newItem.category = header;
//                 break;
//             }
//         }
//         if (!found)
//             newItem.category = "Sightseeing";



//         //const result = await dbCreate(newItem.id, newItem.title, newItem.thumbnail, newItem.link, newItem.updatedTs, newItem.active, newItem.category, newItem.newest);

//     }
// }

// export const dbCreate = async (id, title, thumbnail, link, updatedTs, active, category, newest) => {
//     try {
//         await setDoc(doc(db, logCollectionName, id), {
//             title: title,
//             thumbnail: thumbnail,
//             link: link,
//             updatedTs: updatedTs,
//             active: active,
//             category: category,
//             newest: newest
//         });
//         return true;
//     }
//     catch (error) {
//         console.log(error, id, title);
//         return false;
//     }
// }
