import ImageWood1 from '../assets/wood/wood1.jpg';
import ImageWood2 from '../assets/wood/wood2.jpg';
import { Container, Row, Col } from 'react-bootstrap';

export default function Page() {
  return (
    <Container>
      <Row>
        <Col className="center">
        <img src={ImageWood1} width="640" height="537" alt="Skål-2" />
        <p>Skål 11 cm i diameter - træsort: rødel</p>
        <br />
        <br />
        <img src={ImageWood2} width="640" height="467" alt="Skål-3-2011-04-02" />
        <p>
          Skål - 10,5 cm i diameter - træsort: birk
        </p>
      </Col>
    </Row>
    </Container >
  );
}

