import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

import Page from './Page';
import Home from './Home';
import Allan from './Allan';
import Wood from './Wood';
import Brygning from './brygning/Brygning';
import Christian from './Christian';
import Photos from './Photos';
import Log from './Log';
import Shopping from './Shopping';
import NoPage from './404';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Page />}>
      <Route path="/" element={<Home />} />
      <Route path="allan" element={<Allan />} />
      <Route path="allan/wood" element={<Wood />} />
      <Route path="brygning" element={<Brygning />} />
      <Route path="christian" element={<Christian />} />
      <Route path="fotoalbum" element={<Photos />} />
      <Route path="log" element={<Log />} />
      <Route path="shopping" element={<Shopping />} />
      <Route path="*" element={<NoPage />} />
    </Route>
  )
);

function Router() {
  return (
    <RouterProvider router={router} />
  );
}

export default Router;
