import React, { useEffect } from 'react';
import maj from '../assets/forside/solholt2009maj.jpg';
import flag from '../assets/forside/flag.png';

function Home() {

  useEffect(() => {
    document.title = 'Solholt';
  }, []);

  const imgMonthSelector = [
    { month: 'januar', img: maj, description: 'Solholt maj 2009' },
    { month: 'februar', img: maj, description: 'Solholt maj 2009' },
    { month: 'marts', img: maj, description: 'Solholt maj 2009' },
    { month: 'april', img: maj, description: 'Solholt maj 2009' },
    { month: 'maj', img: maj, description: 'Solholt maj 2009' },
    { month: 'juni', img: maj, description: 'Solholt maj 2009' },
    { month: 'juli', img: maj, description: 'Solholt maj 2009' },
    { month: 'august', img: maj, description: 'Solholt maj 2009' },
    { month: 'september', img: maj, description: 'Solholt maj 2009' },
    { month: 'oktober', img: maj, description: 'Solholt maj 2009' },
    { month: 'november', img: maj, description: 'Solholt maj 2009' },
    { month: 'december', img: maj, description: 'Solholt maj 2009' }
  ];
  const month = new Date().getMonth();
  const imgPicker = imgMonthSelector[month];

  const birthdays = [
    { name: "Niels Aage Møller Larsen", day: 24, month: 5 },
    { name: "Lene Margrethe Larsen", day: 19, month: 12 },
    { name: "Niels Christian Larsen", day: 8, month: 2 },
    { name: "Birgit Levin Kehlet", day: 11, month: 6 },
    { name: "Morten Levin Kehlet", day: 17, month: 12 },
    { name: "Dennis Levin Kehlet", day: 9, month: 10 },
    { name: "Johnny Kehlet", day: 6, month: 3 },
    { name: "Jørgen Bernhard Frederiksen", day: 20, month: 8 },
    { name: "Karl Erik Frederiksen", day: 15, month: 2 },
    { name: "John Frederiksen", day: 13, month: 1 },
    { name: "Michael Frederiksen", day: 30, month: 7 },
    { name: "Liselotte Frederiksen", day: 21, month: 9 },
    { name: "Inger Frederiksen", day: 2, month: 9 },
    { name: "Susanne Frederiksen", day: 5, month: 9 },
    { name: "Gitte Frederiksen", day: 27, month: 4 },
    { name: "Helle Frederiksen", day: 19, month: 7 },
    { name: "Helen Frederiksen", day: 23, month: 11 },
    { name: "Carsten Frederiksen", day: 21, month: 5 },
    { name: "Maja Frederiksen", day: 12, month: 10 },
    { name: "Britta Frederiksen", day: 3, month: 7 },
    { name: "Kristina Andreasen Frederiksen", day: 8, month: 9 },
    { name: "Simon Andreasen Frederiksen", day: 8, month: 9 },
    { name: "Tina Magnussen Borch", day: 8, month: 9 },
    { name: "Thomas Frederiksen", day: 28, month: 9 },
    { name: "Elionora (Nora) Larsen", day: 12, month: 10 },
    { name: "Ryan Maloney", day: 21, month: 3 },
    { name: "Cody Maloney", day: 22, month: 3 },
    { name: "Casper Olsen", day: 19, month: 3 },
    { name: "Nanna Chor Frederiksen", day: 15, month: 4 },
    { name: "Arne Molsen Larsen", day: 27, month: 3 },
    { name: "Brian Molsen Larsen", day: 4, month: 2 },
    { name: "Anni Molsen Larsen", day: 24, month: 8 },
    { name: "Karina Molsen Poulsen", day: 7, month: 7 },
    { name: "Jesper Rindom Poulsen", day: 22, month: 3 },
    { name: "Allan Molsen Larsen", day: 25, month: 2 },
    { name: "Sebastian Molsen Poulsen", day: 6, month: 11 },
    { name: "Isabella Molsen Poulsen", day: 16, month: 6 },
    { name: "Aase Larsen", day: 18, month: 12 },
    { name: "Per Larsen", day: 12, month: 1 },
    { name: "Klaus Larsen", day: 15, month: 5 },
    { name: "Cecilie Lykke Molsen Bowman", day: 12, month: 10 },
    { name: "Thomas Dofoz", day: 28, month: 9 },
    { name: "Margrethe Dahl", day: 21, month: 9 },
    { name: "Rasmus Dahl Larsen", day: 28, month: 4 },
    { name: "Mikkel Dahl", day: 22, month: 9 },
    { name: "Anna Dahl Larsen", day: 6, month: 2 }
  ];

  return (
    <div className="center row">
      <div className='col-2'>
        <br />
      </div>
      <div className='col'>
        <img src={imgPicker.img} alt={imgPicker.description} className="img-fluid" />
      </div>
      <div className='col-2'>
        {
          birthdays.map((birthday) => {
            if (birthday.month === month + 1 && birthday.day === new Date().getDate()) {
              return (
                <div>
                  <br />
                  <img src={flag} className='flag' alt='' />
                  <br />
                  {birthday.name}
                  <br />
                  <br />
                </div>
              );
            }
            else
              return null;
          }
          )
        }
      </div>
    </div>
  );
}

export default Home;
