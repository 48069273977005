import { query, getDocs, collection, where, setDoc, deleteDoc, doc, orderBy } from "firebase/firestore";
import { db } from '../utils/firebase';
import moment from 'moment';
import 'moment/locale/da';
moment().locale('da');

const logCollectionName = "log";

export const dbCreateLog = async (id, newLog) => {
    try {
        await setDoc(doc(db, logCollectionName, id), {
            name: newLog,
            done: false,
            date: new Date()
        });
        return true;
    }
    catch (error) {
        console.log(error);
        return false;
    }
}

export const dbUpdateLog = async (id, text, done, date) => {
    try {
        await setDoc(doc(db, logCollectionName, id), {
            name: text,
            done: done,
            date: date
        });
        return true;
    }
    catch (error) {
        console.log(error);
        return false;
    }
}

export const dbDeleteLog = async (id) => {
    try {
        await deleteDoc(doc(db, logCollectionName, id));
        return true;
    }
    catch (error) {
        console.log(error);
        return false;
    }
}

export const dbGetLog = async () => {
    try {
        const collectionRef = collection(db, logCollectionName);

        const queryDone = query(
            collectionRef,
            where("date", ">", moment().add(-2, 'years').toDate()),
            where("done", "==", true),
            orderBy("date", "desc"));

        const snapshotDone = await getDocs(queryDone);
        const doneData = snapshotDone.docs.map((doc) => (
            {
                id: doc.id,
                name: doc.data().name,
                done: doc.data().done,
                date: moment(doc.data().date.toDate())
            }));

        const queryPending = query(
            collectionRef,
            where("done", "==", false),
            orderBy("date", "desc"));

        const snapshotPending = await getDocs(queryPending);
        const dataPending = snapshotPending.docs.map((doc) => (
            {
                id: doc.id,
                name: doc.data().name,
                date: moment(doc.data().date.toDate())
            }));

        return [doneData, dataPending];
    }
    catch (error) {
        console.log(error);
        return [[], []];
    }
}

export const dbSearchText = async (text) => {
    try {
        const collectionRef = collection(db, logCollectionName);

        const querySearch = query(
            collectionRef,
            orderBy("date", "desc"));

        const querySnapshot = await getDocs(querySearch);

        const data = querySnapshot.docs.filter(
            (doc) => (doc.data().name.toLowerCase().includes(text.toLowerCase())))
            .map((doc) => (
                {
                    id: doc.id,
                    name: doc.data().name,
                    done: doc.data().done,
                    date: moment(doc.data().date.toDate())
                }));
        return data;

    }
    catch (error) {
        console.log(error);
        return [];
    }
}