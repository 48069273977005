import ImgHumle from '../../assets/bryg/humle.jpg';
import { Container, Row, Col } from 'react-bootstrap';

export default function Page() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Brygning</h1>
          <p>
            For et par hundrede år siden var ølbrygning en naturlig del
            af husholdningen på gårdene. Ølbrygning havde vi som en hobby på
            Solholt, men har ikke brygget siden 2012.
          </p>
          <p><a href="/Brygning/generelt-om-brygning">Lidt generelt om brygning</a></p>
          <p><a href="/Brygning/brygning-paa-solholt">Brygning vi på Solholt</a></p>
          <p>
            <a href="/Brygning/1-bryg-engelsk-ale">1. Bryg - Engelsk Ale - ESB</a><br />
            <a href="/Brygning/2-bryg-engelsk-ale">2. Bryg - Engelsk Ale - ESB</a><br />
            <a href="/Brygning/3-bryg-tjekkisk-pilsner">3. Bryg - Solholt Tjekkisk Pilsner</a><br />
            <a href="/Brygning/4-bryg-engelsk-ale">4. Bryg - Solholt Jule Ale</a><br />
            <a href="/Brygning/5-bryg-solholt-pilsner">5. Bryg - Solholt Pilsner</a><br />
            <a href="/Brygning/6-bryg-solholt-hvede">6. Bryg - Solholt Hvede</a><br />
            <a href="/Brygning/vinoel-hybrid">Eksperiment 1 - Vin/øl hybrid</a><br />
            <a href="/Brygning/7-bryg-solholt-hvede2">7. Bryg - Solholt Hvede 2.0</a><br />
            <a href="/Brygning/8-bryg-schwarzbier">8. Bryg - Schwarzbier</a><br />
            <a href="/Brygning/9-bryg-solholt-classic">9. Bryg - Solholt Classic</a><br />
            10. Bryg - Solholt Pilsner<br />
            11. Bryg - Solholt Dubbel (Belgisk Dubbel)
          </p>
        </Col>
        <Col> 
          <img src={ImgHumle} width="400" height="533" alt="Humle" />
        </Col>
      </Row>
    </Container >
  );
}

