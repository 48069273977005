import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink, useLocation } from 'react-router-dom';
import ImageTop from '../assets/top.jpg';
import { useSiteContext } from '../utils/siteContext';

function Navigation() {
    const { user, authenticated } = useSiteContext();
    const location = useLocation();

    return (
        <>
            {
                location.pathname !== "/shopping" &&
                <>
                    <div id="topImg" className='center'>
                        <img src={ImageTop} alt="Solholt" />
                    </div>
                    <Navbar expand="lg" bg="dark" className='center'>
                        <div className='navbar-toggler-outer'>
                            <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        </div>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <NavLink className='nav-link' to="/">Forside</NavLink>
                                <NavLink className='nav-link' to="allan">Allan</NavLink>
                                <NavLink className='nav-link' to="christian">Christian</NavLink>
                                <NavLink className='nav-link' to="fotoalbum">Fotoalbum</NavLink>
                                {
                                    authenticated && <>
                                        <NavLink className='nav-link' to="log">Log</NavLink>
                                        <NavLink className='nav-link' to="shopping">Indkøb</NavLink>
                                    </>
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                    {
                        authenticated && <div id="userName">Velkommen {user}</div>
                    }
                </>
            }
        </>
    );
}

export default Navigation;