import { Row, Col, Button } from 'react-bootstrap';
import { useEffect, useState, useRef } from 'react';
import { useSiteContext } from '../utils/siteContext';
import { dbCreateLog, dbUpdateLog, dbDeleteLog, dbSearchText, dbGetLog } from '../models/logModel';
import moment from 'moment';
import 'moment/locale/da';
moment().locale('da');

export default function Page() {
  const { login, authenticated } = useSiteContext();

  const [log, setLog] = useState([]);
  const [pending, setPending] = useState([]);
  const [newLog, setNewLog] = useState("");
  const searchRef = useRef();

  const createLog = async () => {
    if (newLog) {
      try {
        await dbCreateLog((new Date()).getTime().toString(), newLog);
        setNewLog("");
        getLog();
      }
      catch (error) {
        console.log(error);
      }
    }
  }

  const handleKeyDownCreate = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      createLog();
    }
  };

  const updateLog = async (id, text) => {
    if (text) {
      try {
        await dbUpdateLog(id, text, false, new Date());
        getLog();
      }
      catch (error) {
        console.log(error);
      }
    }
  }

  const deleteLog = async (id) => {
    try {
      await dbDeleteLog(id);
      getLog();
    }
    catch (error) {
      console.log(error);
    }
  }

  const doneLog = async (item) => {
    try {
      await dbUpdateLog(item.id, item.name, true, new Date());
      getLog();
    }
    catch (error) {
      console.log(error);
    }
  }

  const search = async () => {
    const term = searchRef.current.value;
    if (term === "" || term === undefined) {
      const [doneData] = await dbGetLog();
      setLog(doneData);
    }
    else {
      const data = await dbSearchText(term);
      setLog(data);
    }
  }

  const handleKeyDownSearch = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      search();
    }
  };

  const toggleEdit = (id) => {
    const newPending = pending.map((item) => {
      if (item.id === id) {
        item.edit = !item.edit;
      }
      return item;
    });
    setPending(newPending);
  }

  const getLog = async () => {
    try {
      const [doneData, dataPending] = await dbGetLog();
      setLog(doneData);
      setPending(dataPending);
    }
    catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    document.title = 'Solholt - Log';
    if (authenticated)
      getLog();
  }, [authenticated]);


  return (
    <>
      {
        authenticated && log.length !== 0 && (
          <>
            <Row>
              <h1>Todo</h1>
              {
                pending.map((log, index) => (
                  <Row key={log.id}>
                    <Col xs={4}>
                      {
                        !log.edit && (
                          <>
                            <Button variant="danger" className='custom-btn' size="sm" onClick={() => { if (window.confirm('Bekræft sletning')) deleteLog(log.id) }}>Slet</Button>
                            <Button variant="warning" className='custom-btn' size="sm" onClick={() => { toggleEdit(log.id) }}>Ret</Button>
                            <Button variant="success" className='custom-btn' size="sm" onClick={() => { doneLog(log) }}>Afslut</Button>
                          </>
                        )
                      }
                      {
                        log.edit && (
                          <>
                            <Button variant="warning" size="sm" onClick={() => { toggleEdit(log.id) }}>Fortryd</Button>
                            <Button variant="success" size="sm" onClick={() => { updateLog(log.id, log.newName) }}>Gem</Button>
                          </>
                        )
                      }
                    </Col>
                    <Col xs={8}>
                      {
                        log.edit && (
                          <input type="text" defaultValue={log.name} className="form-control form-control-sm" onChange={(e) => log.newName = e.target.value} />
                        )
                      }
                      {
                        !log.edit && (
                          <>{log.name}</>
                        )
                      }
                    </Col>
                  </Row>
                ))
              }
              <Row>
                <Col className="form-group">
                  <div className="input-group mb-3">
                    <input type="text" value={newLog} 
                    placeholder='Tilføj gøremål'
                    onKeyDown={handleKeyDownCreate} onChange={(e) => setNewLog(e.target.value)} className="form-control form-control-sm" aria-describedby="button-addon1" />
                    <button className='btn btn-primary' type="button" id="button-addon1" onClick={createLog}>Gem</button>
                  </div>
                </Col>
              </Row>
            </Row>
            <Row>
              <h1>Log</h1>
              <Row>
                <div className="input-group mb-3">
                  <input type="text" onKeyDown={handleKeyDownSearch} className="form-control form-control-sm" aria-describedby="button-addon2" ref={searchRef} />
                  <button className="btn btn-primary" type="button" id="button-addon2" onClick={search}>Søg</button>
                </div>
              </Row>
              {
                log.map((log) => (
                  <Row key={log.id}>
                    <Col xs={3}>
                      {moment(log.date).format('YYYY MMM')}
                    </Col>
                    <Col>
                      {log.name} {log.done}
                    </Col>
                  </Row>
                ))
              }
            </Row>
          </>
        )
      }
      {
        !authenticated && (
          <Button variant="primary" onClick={login}>Login</Button>
        )
      }
    </>
  );
}

